import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import AboutPanel from "../components/about-panel"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import AppsPanel from "../components/apps-panel"

const CloudPage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="AWS and GCP Cloud Application Engineers" />
        <Hero title="On-Demand AWS and GCP Cloud Application Engineers" description="Our world-class engineers will help you with everything from scoping and design to development, UAT, and deployment." image="pages/cloud-hero.jpg" />
        <AboutPanel title="I can help you take your cloud infraestructure to the next level." description="Hire high-performing, on-demand cloud engineers" image="pages/cloud-developer.jpg"  />
        <AppsPanel description="Looking to take your business to the next level with distributed cloud solutions? Look no further! Our Cloud Application Engineers are world-class experts in AWS & GCP technologies, and can help you scope, design, develop, and deploy your applications quickly and easily. With our on-demand services, you'll be able to ramp up your development process easily and get your applications into production fast. So why wait? Contact us today to get started!" />

      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default CloudPage
